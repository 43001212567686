/**
 * This file contains flags that zone.js will use.
 * Angular change detection is highly reliant on zone.js,
 * so be careful when adding in or modifying existing flags
 * as it may have unintended consequences.
 * To see all the flags that can be configured:
 * node_modules/zone.js/dist/zone.configurations.api.js
 */

(<any>window).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
(<any>window).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
(<any>window).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
(<any>window).__Zone_enable_cross_context_check = true; // bypass `zone.js` patch for IE/Edge addEventListener
